import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home.vue";
import Tours from "../views/Tours.vue";
import User from "../views/User.vue";
import AppSlideshow from "../views/AppSlideshow.vue";
import Login from "../views/Login.vue";
import SendReset from "../views/SendReset.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
  },
  {
    path: "/about",
    name: "About",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/About.vue"),
  },
  {
    path: "/tours",
    name: "Zájezdy",
    component: Tours,
  },
  {
    path: "/user",
    name: "Profil",
    component: User,
  },
  {
    path: "/app-slideshow",
    name: "Banery v aplikaci",
    component: AppSlideshow,
  },
  {
    path: "/login",
    name: "Přihlášení",
    component: Login,
  },
  {
    path: "/send-reset",
    name: "Přihlášení",
    component: SendReset,
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;
