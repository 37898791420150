<template>
  <div class="tours">
    <v-col cols="auto">
      <v-dialog
        transition="dialog-top-transition"
        max-width="600"
        v-model="dialogClients"
      >
        <v-card>
          <v-toolbar color="primary" dark>Vytvořit nový kontakt</v-toolbar>
          <v-card-text>
            <v-form v-model="valid" ref="contactForm">
              <v-container>
                <v-row>
                  <v-col cols="12" md="6">
                    <v-combobox
                      clearable
                      persistent-hint
                      :rules="nameRules"
                      hint="Pište pro vyhledávání"
                      label="Klient"
                      :items="clients"
                      item-text="name"
                      item-value="id"
                      required
                      @change="onClientSelected"
                    ></v-combobox>
                  </v-col>
                  <v-col cols="12" md="6">
                    <v-text-field
                      v-model="client.address"
                      :rules="addressRules"
                      label="Adresa"
                      :disabled="client.id !== null"
                      required
                    ></v-text-field>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12" md="6">
                    <v-text-field
                      v-model="client.ICO"
                      :rules="icoRules"
                      :counter="8"
                      label="IČO"
                      :disabled="client.id !== null"
                      required
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" md="6">
                    <v-text-field
                      v-model="client.DIC"
                      :rules="dicRules"
                      :counter="10"
                      label="DIČ"
                      :disabled="client.id !== null"
                      required
                    ></v-text-field>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12" md="4">
                    <v-text-field
                      v-model="contact.name"
                      :rules="nameRules"
                      label="Jméno"
                      required
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" md="4">
                    <v-text-field
                      v-model="contact.phone"
                      :rules="phoneRules"
                      label="Telefon"
                      required
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" md="4">
                    <v-text-field
                      v-model="contact.email"
                      :rules="emailRules"
                      label="E-mail"
                      required
                    ></v-text-field>
                  </v-col>
                </v-row>
              </v-container>
            </v-form>
          </v-card-text>
          <v-card-actions class="justify-end">
            <v-btn text @click="saveContact">Uložit</v-btn>
            <v-btn text @click="dialogClients = false">Zavřít</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <v-dialog
        transition="dialog-top-transition"
        max-width="1000"
        v-model="dialogTours"
      >
        <template>
          <v-card>
            <v-toolbar color="primary" dark>Přidat nový zájezd</v-toolbar>
            <v-card-text>
              <v-form v-model="valid" ref="form">
                <v-container>
                  <v-row>
                    <v-col cols="6" md="8" sm="8">
                      <v-autocomplete
                        v-model="selectedContact"
                        :items="contacts"
                        item-text="full_name"
                        item-value="id"
                        color="white"
                        label="Klient"
                        placeholder="Začněte psát pro vyhledávání..."
                        prepend-icon="mdi-account-search"
                        return-object
                      ></v-autocomplete> </v-col
                    ><v-col cols="6" md="2" sm="2">
                      <v-btn
                        depressed
                        color="primary"
                        @click="dialogClients = true"
                      >
                        <v-icon>mdi-account-plus</v-icon>
                      </v-btn>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="4" md="4" sm="2">
                      <v-menu
                        ref="dateMenu"
                        v-model="dateMenu"
                        :close-on-content-click="false"
                        transition="scale-transition"
                        offset-y
                        min-width="auto"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-text-field
                            v-model="start_date"
                            label="Datum přistavení"
                            prepend-icon="mdi-calendar"
                            readonly
                            :rules="dateRules"
                            v-bind="attrs"
                            v-on="on"
                          ></v-text-field>
                        </template>
                        <v-date-picker
                          v-model="start_date"
                          no-title
                          scrollable
                          @input="dateMenu = false"
                        >
                        </v-date-picker>
                      </v-menu>
                    </v-col>

                    <v-col cols="4" md="4" sm="2">
                      <v-menu
                        ref="timeMenu"
                        v-model="timeMenu"
                        :close-on-content-click="false"
                        :nudge-right="40"
                        transition="scale-transition"
                        offset-y
                        max-width="290px"
                        min-width="290px"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-text-field
                            v-model="start_time"
                            label="Čas přistavení"
                            prepend-icon="mdi-clock-time-four-outline"
                            :rules="timeRules"
                            readonly
                            v-bind="attrs"
                            v-on="on"
                          ></v-text-field>
                        </template>
                        <v-time-picker
                          v-if="timeMenu"
                          v-model="start_time"
                          format="24hr"
                          full-width
                          @click:minute="$refs.timeMenu.save(start_time)"
                        ></v-time-picker>
                      </v-menu>
                    </v-col>
                    <v-col cols="4" md="4" sm="4">
                      <v-text-field
                        v-model="capacity"
                        :rules="capacityRules"
                        label="Kapacita"
                        required
                      ></v-text-field>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="12" md="6">
                      <v-text-field
                        v-model="start_address"
                        :rules="addressRules"
                        label="Adresa přistavení"
                        required
                      ></v-text-field>
                    </v-col>
                    <v-col cols="6" md="3">
                      <v-text-field
                        v-model="price"
                        :rules="priceRules"
                        label="Cena"
                        required
                      ></v-text-field>
                    </v-col>
                    <v-col cols="6" md="3">
                      <v-select
                        v-model="payment_type"
                        :items="paymentTypes"
                        label="Způsob platby"
                      ></v-select>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="12" md="6">
                      <v-textarea v-model="route" label="Trasa"></v-textarea>
                    </v-col>
                    <v-col cols="12" md="6">
                      <v-textarea
                        v-model="description"
                        label="Detaily"
                      ></v-textarea>
                    </v-col>
                  </v-row>
                  <v-row> <v-col cols="12" md="4"> </v-col></v-row>
                </v-container>
              </v-form>

              <v-alert
                v-if="dialogErrors"
                border="top"
                color="red lighten-2"
                dark
              >
                {{ dialogErrors }}
              </v-alert>
            </v-card-text>
            <v-card-actions class="justify-end"
              ><v-btn depressed color="primary" v-on:click="save()"
                >Uložit</v-btn
              >
              <v-btn text @click="dialogTours = false">Zavřít</v-btn>
            </v-card-actions>
          </v-card>
        </template>
      </v-dialog>
      <v-btn color="primary" @click="openToursDialog">Přidat zájezd</v-btn>
    </v-col>

    <v-data-table
      :headers="headers"
      :items="tours"
      :items-per-page="15"
      class="elevation-1"
    >
      <template v-slot:[`item.start_date`]="{ item }">
        <span>{{ formatDate(item.start_date) }}</span>
      </template>
      <template v-slot:[`item.id`]="{ item }">
        <span>
          <v-btn
            @click="() => onEditRow(item)"
            depressed
            color="primary"
            class="mx-1 my-1"
          >
            <v-icon>mdi-pencil</v-icon>
          </v-btn>
          <v-btn
            @click="() => onDeleteRow(item)"
            depressed
            color="error"
            class="mx-1 my-1"
          >
            <v-icon>mdi-trash-can</v-icon>
          </v-btn>
        </span>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import moment from "moment";

export default {
  name: "Tours",
  components: {},
  methods: {
    formatDate: function (date){
      return moment(String(date)).format("DD. MM. YYYY HH:mm");
    },
    openToursDialog: function () {
      this.tourEditId = null;
      this.dialogTours = true;

      if (this.$refs.form) {
        this.$refs.form.reset();
      }
    },
    onEditRow: function (item) {
      this.tourEditId = item.id;
      this.dialogTours = true;

      console.log(item);

      this.start_date = moment(String(item.start_date)).format("YYYY-MM-DD");
      this.start_time = moment(String(item.start_date)).format("HH:mm");

      this.capacity = item.capacity;
      this.start_address = item.start_address;
      this.price = item.price;
      this.route = item.route;
      this.description = item.description;

      this.selectedContact = this.contacts.find(
        (contact) => contact.id == item.contact_id
      );
    },
    onDeleteRow: function (item) {
      console.log(item);
    },
    onClientSelected: function (client) {
      console.log(client);
      if (client == null) {
        this.client = {
          id: null,
          name: null,
          address: null,
          ICO: null,
          DIC: null,
        };
      } else if (client.id) {
        this.client = client;
      } else {
        this.client = {
          id: null,
          name: client,
          address: null,
          ICO: null,
          DIC: null,
        };
      }
    },
    saveContact: async function () {
      if (!this.$refs.contactForm.validate()) {
        return;
      }
      try {
        let client = this.client;
        if (this.client.id == null) {
          let response = await fetch(
            "https://dev2.autobusyvkj.cz/api/admin/client",
            {
              credentials: "include",
              mode: "cors", // no-cors, *cors, same-origin
              method: "POST", // *GET, POST, PUT, DELETE, etc.
              headers: {
                "Content-Type": "application/json",
              },
              body: JSON.stringify(this.client),
            }
          );

          client = await response.json();
        }

        if (client.id) {
          let newClients = this.clients;
          newClients.push(client);
          this.clients = newClients;

          let response2 = await fetch(
            "https://dev2.autobusyvkj.cz/api/admin/contact",
            {
              credentials: "include",
              mode: "cors", // no-cors, *cors, same-origin
              method: "POST", // *GET, POST, PUT, DELETE, etc.
              headers: {
                "Content-Type": "application/json",
              },
              body: JSON.stringify({ ...this.contact, client_id: client.id }),
            }
          );

          let contact = await response2.json();

          if (contact.id) {
            contact.full_name =
              contact.name + " (" + contact.email + ") " + " | " + client.name;
            let newContacts = this.contacts;
            newContacts.push(contact);
            this.contacts = newContacts;

            this.$refs.contactForm.reset();

            this.dialogClients = false;
            this.selectedContact = contact;
          }
        } else {
          let errors = "";
          for (const [key, value] of Object.entries(client)) {
            console.log(`${key}: ${value}`);
            errors += value;
          }
          this.dialogErrors = "Chyba! " + errors;
        }

        console.log(client);
      } catch (error) {
        alert(error.message);
      }
    },
    save: async function () {
      if (!this.$refs.form.validate()) {
        return;
      }
      try {
        let response = await fetch(
          "https://dev2.autobusyvkj.cz/api/admin/tour",
          {
            credentials: "include",
            mode: "cors", // no-cors, *cors, same-origin
            method: "POST", // *GET, POST, PUT, DELETE, etc.
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              id: this.tourEditId,
              start_date: this.start_date,
              end_date: this.start_date,
              capacity: this.capacity,
              start_address: this.start_address,
              route: this.route,
              description: this.description,
              payment_type: this.payment_type,
              price: this.price,
              contact_id: this.selectedContact.id,
            }),
          }
        );

        let data = await response.json();
        if (data.id) {
          let newTours = this.tours;
          if (this.tourEditId) {
            newTours = newTours.filter((tour) => tour.id != data.id);
          }
          newTours.push(data);
          this.tours = newTours;
          this.$refs.form.reset();
          this.dialogTours = false;
        } else {
          let errors = "";
          for (const [key, value] of Object.entries(data)) {
            console.log(`${key}: ${value}`);
            errors += value;
          }
          this.dialogErrors = "Chyba! " + errors;
        }

        console.log(data);
      } catch (error) {
        alert(error.message);
      }
    },
    fetchData: async function () {
      let response = await fetch(
        "https://dev2.autobusyvkj.cz/api/admin/clients",
        {
          credentials: "include",
          mode: "cors", // no-cors, *cors, same-origin
          method: "GET", // *GET, POST, PUT, DELETE, etc.
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      this.clients = await response.json();

      response = await fetch("https://dev2.autobusyvkj.cz/api/admin/contacts", {
        credentials: "include",
        mode: "cors", // no-cors, *cors, same-origin
        method: "GET", // *GET, POST, PUT, DELETE, etc.
        headers: {
          "Content-Type": "application/json",
        },
      });

      let contacts = await response.json();

      contacts.forEach((contact) => {
        contact.full_name =
          contact.name +
          " (" +
          contact.email +
          ") " +
          " | " +
          this.clients.find((client) => client.id == contact.client_id).name;
      });

      this.contacts = contacts;

      response = await fetch("https://dev2.autobusyvkj.cz/api/admin/tours", {
        credentials: "include",
        mode: "cors", // no-cors, *cors, same-origin
        method: "GET", // *GET, POST, PUT, DELETE, etc.
        headers: {
          "Content-Type": "application/json",
        },
      });

      let tours = await response.json();

      tours.forEach((tour) => {
        tour.contact = this.contacts.find(
          (contact) => contact.id == tour.contact_id
        ).full_name;
      });

      this.tours = tours;
    },
  },
  data: () => ({
    contact: {
      id: null,
      name: null,
      email: null,
      phone: null,
    },
    client: {
      id: null,
      name: null,
      address: null,
      ICO: null,
      DIC: null,
    },
    dialogErrors: null,
    dialogClients: false,
    dialogTours: false,
    headers: [
      {
        text: "Datum",
        align: "start",
        sortable: true,
        value: "start_date",
      },
      { text: "Kapacita", value: "capacity" },
      { text: "Adresa", value: "start_address" },
      { text: "Trasa", value: "route" },
      { text: "Detaily", value: "description" },
      { text: "Typ platby", value: "payment_type" },
      { text: "Cena", value: "price" },
      { text: "Zákazník", value: "contact" },
      { text: "", value: "id" },
    ],
    tours: [],
    contacts: [],
    clients: [],
    valid: false,
    selectedContact: null,
    start_date: null,
    start_time: null,
    capacity: null,
    start_address: null,
    route: null,
    description: null,
    payment_type: null,
    price: null,
    capacityRules: [
      (v) => !!v || "Kapacita je povinná.",
      (v) => (!isNaN(parseFloat(v)) && v > 0) || "Neplatná kapacita.",
    ],
    dateRules: [(v) => !!v || "Datum je povinné."],
    timeRules: [(v) => !!v || "Čas je povinný."],
    addressRules: [(v) => !!v || "Adresa je povinná."],
    nameRules: [(v) => !!v || "Jméno je povinné."],
    routeRules: [(v) => !!v || "Trasa je povinná."],
    priceRules: [
      (v) => !!v || "Cena je povinná.",
      (v) => (!isNaN(parseFloat(v)) && v > 0) || "Neplatná cena.",
    ],
    phoneRules: [
      (v) => !!v || "Telefon je povinný.",
      (v) => /\b\d{9}\b/.test(v) || "Telefon není validní",
    ],
    icoRules: [
      (v) => !!v || "IČO je povinné.",
      (v) => /\b\d{8}\b/.test(v) || "IČO není validní",
    ],
    dicRules: [
      (v) => !!v || "DIČ je povinné.",
      (v) => /\b.{2}\d{8}\b/.test(v) || "DIČ není validní",
    ],
    emailRules: [
      (v) => !!v || "E-mail je povinný",
      (v) => /.+@.+/.test(v) || "E-mail není validní",
    ],
    timeMenu: false,
    dateMenu: false,
    paymentTypes: ["hotově", "převodem"],
    tourEditId: null,
  }),
  created() {
    // fetch the data when the view is created and the data is
    // already being observed
    this.fetchData();
  },
};
</script>
