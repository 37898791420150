<template>
  <div class="login">
    <v-container>
      <v-row>
        <v-col cols="4" class="mx-auto mt-10">
          <v-card elevation="2" class="px-5 py-5">
            <v-card-title> Autobusy VKJ - Obnovení hesla </v-card-title>
            <v-form ref="form" v-model="valid" lazy-validation>
              <v-text-field
                v-model="email"
                :rules="emailRules"
                label="E-mail"
                required
              ></v-text-field>
              <v-btn
                :disabled="!valid"
                color="primary"
                class="mr-4"
                @click="sendReset"
              >
                Odeslat odkaz k Obnovení
              </v-btn>
              <v-btn text="true" color="secondary" class="mr-4" to="/login">
                Zpět na přihlášení
              </v-btn>
            </v-form>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
export default {
  name: "SendReset",
  data: () => ({
    valid: true,
    email: "",
    emailRules: [
      (v) => !!v || "E-mail je povinný",
      (v) => /.+@.+\..+/.test(v) || "Neplatný email.",
    ],
  }),

  methods: {
    sendReset: async function () {
      if (this.$refs.form.validate()) {
        try {
          const response = await fetch(
            "https://dev2.autobusyvkj.cz/api/send-reset-link",
            {
              credentials: "include",
              mode: "cors", // no-cors, *cors, same-origin
              method: "POST", // *GET, POST, PUT, DELETE, etc.
              headers: {
                "Content-Type": "application/json",
                //"Content-Type": "application/x-www-form-urlencoded",
              },
              body: JSON.stringify({
                email: this.email,
              }),
            }
          );
          let data = await response.json();
          if (data.error) {
            alert(data.error);
          } else {
            alert(data.status);
          }
        } catch (error) {
          alert(error.message);
        }
      }
    },
  },
};
</script>
