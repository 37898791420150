<template>
  <div class="user">
    <v-container>
      <v-row>
        <v-col cols="10" class="mx-auto mt-10">
          <v-card elevation="2" class="px-5 py-5">
            <v-card-title> Nahrát banery do aplikace</v-card-title>
            <v-form ref="form" v-model="valid" lazy-validation>
              <v-file-input
                accept="image/png, image/jpeg"
                label="Obrázek baneru"
                counter
                show-size
                truncate-length="25"
                v-model="file"
                required
                :rules="fileRules"
              ></v-file-input>

              <v-btn
                :disabled="!file"
                color="primary"
                class="mr-4"
                @click="save"
              >
                Uložit
              </v-btn>
            </v-form>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
    <v-container>
      <v-row v-for="item in slideshowItems" :key="item.id">
        <v-col cols="10" class="mx-auto mt-10">
          <v-card elevation="2" class="px-5 py-5">
            <v-img
              max-height="200"
              max-width="1200"
              :src="item.file_url"
            ></v-img>
            <v-btn
              color="error"
              class="mr-4 mx-auto"
              @click="() => remove(item.id)"
            >
              Smazat
            </v-btn>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
export default {
  name: "AppSlideshow",
  components: {},
  methods: {
    remove: async function (id) {
      try {
        let response = await fetch(
          "https://dev2.autobusyvkj.cz/api/admin/app/slideshow/remove",
          {
            credentials: "include",
            mode: "cors", // no-cors, *cors, same-origin
            method: "POST", // *GET, POST, PUT, DELETE, etc.
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({ id }),
          }
        );

        this.slideshowItems = await response.json();
      } catch (error) {
        alert(error.message);
      }
    },
    save: async function () {
      if (!this.$refs.form.validate()) {
        return;
      }
      try {
        let formData = new FormData();
        formData.append("file", this.file);

        let response = await fetch(
          "https://dev2.autobusyvkj.cz/api/admin/app/slideshow/add",
          {
            credentials: "include",
            mode: "cors", // no-cors, *cors, same-origin
            method: "POST", // *GET, POST, PUT, DELETE, etc.
            // headers: {
            //   "Content-Type": "multipart/form-data",
            // },
            body: formData,
          }
        );

        let data = await response.json();
        if (data.id) {
          this.$refs.form.reset();
          this.slideshowItems.push(data);
        } else {
          let errors = "";
          for (const [key, value] of Object.entries(data)) {
            console.log(`${key}: ${value}`);
            errors += value;
          }
          alert("Chyba! " + errors);
        }

        console.log(data);
      } catch (error) {
        alert(error.message);
      }
    },
    fetchData: async function () {
      let response = await fetch(
        "https://dev2.autobusyvkj.cz/api/admin/app/slideshows",
        {
          credentials: "include",
          mode: "cors", // no-cors, *cors, same-origin
          method: "GET", // *GET, POST, PUT, DELETE, etc.
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      this.slideshowItems = await response.json();
    },
  },
  data: () => ({
    valid: false,
    file: null,
    fileRules: [
      (value) =>
        !value ||
        value.size < 10000000 ||
        "Velikost by měla být maximálně 10 MB!",
    ],
    slideshowItems: [],
  }),
  created() {
    // fetch the data when the view is created and the data is
    // already being observed
    this.fetchData();
  },
};
</script>
