<template>
  <div class="login">
    <v-container>
      <v-row>
        <v-col cols="4" class="mx-auto mt-10">
          <v-card elevation="2" class="px-5 py-5">
            <v-card-title> Autobusy VKJ - Přihlášení </v-card-title>
            <v-form ref="form" v-model="valid" lazy-validation>
              <v-text-field
                v-model="email"
                :rules="emailRules"
                label="E-mail / uživatelské jméno"
                required
              ></v-text-field>

              <v-text-field
                v-model="password"
                :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                :rules="passwordRules"
                :type="showPassword ? 'text' : 'password'"
                label="Heslo"
                @click:append="showPassword = !showPassword"
              ></v-text-field>

              <v-btn
                :disabled="!valid"
                color="primary"
                class="mr-4"
                @click="login"
              >
                Přihlásit se
              </v-btn>
              <v-btn
                text="true"
                color="secondary"
                class="mr-4"
                to="/send-reset"
              >
                Zapomenuté heslo
              </v-btn>
            </v-form>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
export default {
  name: "Login",
  data: () => ({
    showPassword: false,
    valid: true,
    email: "",
    emailRules: [(v) => !!v || "E-mail, nebo uživatelské jméno je povinné"],
    password: "",
    passwordRules: [(v) => !!v || "Heslo je povinné"],
    select: null,
    items: ["Item 1", "Item 2", "Item 3", "Item 4"],
    checkbox: false,
  }),

  methods: {
    login: async function () {
      if (this.$refs.form.validate()) {
        try {
          const response = await fetch(
            "https://dev2.autobusyvkj.cz/api/login",
            {
              credentials: "include",
              mode: "cors", // no-cors, *cors, same-origin
              method: "POST", // *GET, POST, PUT, DELETE, etc.
              headers: {
                "Content-Type": "application/json",
                //"Content-Type": "application/x-www-form-urlencoded",
              },
              body: JSON.stringify({
                email: this.email,
                password: this.password,
              }),
            }
          );
          let user = (await response.json()).user;
          console.log(user);
          if (user.id) {
            // Cookies.set("user", JSON.stringify(user), { expires: 60 * 365 });
            if (user.v1_user_id) {
              if (window.location.href.endsWith("DEV")) {
                window.location.href =
                  "https://dev1.autobusyvkj.cz/v2login/" +
                  user.v1_user_id +
                  "/" +
                  user.v1_login_token;
              } else {
                window.location.href =
                  "https://backoffice.autobusyvkj.cz/v2login/" +
                  user.v1_user_id +
                  "/" +
                  user.v1_login_token;
              }
            }
          }
        } catch (error) {
          alert(error.message);
        }
      }
    },
  },
};
</script>
