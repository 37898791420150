<template>
  <div class="user">
    <v-container>
      <v-row>
        <v-col cols="10" class="mx-auto mt-10">
          <v-card elevation="2" class="px-5 py-5">
            <v-card-title> Upravit údaje</v-card-title>
            <v-form ref="form" v-model="formValid" lazy-validation>
              <v-text-field
                v-model="user.name"
                :rules="nameRules"
                label="Jméno"
                required
              ></v-text-field>

              <v-text-field
                v-model="user.email"
                :rules="emailRules"
                label="E-mail"
                required
              ></v-text-field>
              <v-text-field
                v-model="user.phone"
                :rules="phoneRules"
                label="Telefon"
                required
              ></v-text-field>

              <v-btn
                :disabled="!formValid"
                color="primary"
                class="mr-4"
                @click="save"
              >
                Uložit
              </v-btn>
            </v-form>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
    <v-container>
      <v-row>
        <v-col cols="10" class="mx-auto mt-10">
          <v-card elevation="2" class="px-5 py-5">
            <v-card-title>Změna hesla</v-card-title>
            <v-form
              ref="formPassword"
              v-model="passwordFormValid"
              lazy-validation
            >
              <v-text-field
                v-model="currentPassword"
                :append-icon="showPassword1 ? 'mdi-eye' : 'mdi-eye-off'"
                :rules="passwordRules"
                :type="showPassword1 ? 'text' : 'password'"
                label="Původní heslo"
                @click:append="showPassword1 = !showPassword1"
              ></v-text-field>

              <v-text-field
                v-model="password"
                :append-icon="showPassword2 ? 'mdi-eye' : 'mdi-eye-off'"
                :rules="passwordRules"
                :type="showPassword2 ? 'text' : 'password'"
                label="Nové heslo"
                @click:append="showPassword2 = !showPassword2"
              ></v-text-field>
              <v-text-field
                v-model="passwordConfirmation"
                :append-icon="showPassword3 ? 'mdi-eye' : 'mdi-eye-off'"
                :rules="passwordRules"
                :type="showPassword3 ? 'text' : 'password'"
                label="Nové heslo znovu"
                @click:append="showPassword3 = !showPassword3"
              ></v-text-field>

              <v-btn
                :disabled="!passwordFormValid"
                :text="true"
                color="error"
                class="mr-4"
                @click="changePassword"
              >
                Změnit heslo
              </v-btn>
            </v-form>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
export default {
  name: "User",
  components: {},
  methods: {
    save: async function () {
      if (!this.$refs.form.validate()) {
        return;
      }
      try {
        let body = JSON.stringify({
          name: this.user.name,
          email: this.user.email,
          phone: this.user.phone,
        });
        console.log("Sending body " + body);
        let response = await fetch(
          "https://dev2.autobusyvkj.cz/api/profile/edit",
          {
            credentials: "include",
            mode: "cors", // no-cors, *cors, same-origin
            method: "POST", // *GET, POST, PUT, DELETE, etc.
            headers: {
              "Content-Type": "application/json",
            },
            body: body,
          }
        );

        let data = await response.json();
        if (data.id) {
          // nothing
        } else {
          let errors = "";
          for (const [key, value] of Object.entries(data)) {
            console.log(`${key}: ${value}`);
            errors += value;
          }
          alert("Chyba! " + errors);
        }

        console.log(data);
      } catch (error) {
        alert(error.message);
      }
    },
    changePassword: async function () {
      if (!this.$refs.formPassword.validate()) {
        return;
      }
      try {
        let body = JSON.stringify({
          current_password: this.currentPassword,
          password: this.password,
          password_confirmation: this.passwordConfirmation,
        });
        console.log("Sending body " + body);
        let response = await fetch(
          "https://dev2.autobusyvkj.cz/api/profile/change-password",
          {
            credentials: "include",
            mode: "cors", // no-cors, *cors, same-origin
            method: "POST", // *GET, POST, PUT, DELETE, etc.
            headers: {
              "Content-Type": "application/json",
            },
            body: body,
          }
        );

        let data = await response.json();
        if (data.id) {
          this.$refs.formPassword.reset();
          this.showPassword1 = this.showPassword2 = this.showPassword3 = false;
        } else {
          let errors = "";
          for (const [key, value] of Object.entries(data)) {
            console.log(`${key}: ${value}`);
            errors += value;
          }
          alert("Chyba! " + errors);
        }

        console.log(data);
      } catch (error) {
        alert(error.message);
      }
    },
    fetchData: async function () {
      let response = await fetch("https://dev2.autobusyvkj.cz/api/user", {
        credentials: "include",
        mode: "cors", // no-cors, *cors, same-origin
        method: "GET", // *GET, POST, PUT, DELETE, etc.
        headers: {
          "Content-Type": "application/json",
        },
      });

      this.user = await response.json();
    },
  },
  data: () => ({
    formValid: true,
    passwordFormValid: false,
    user: {
      id: null,
      name: null,
      email: null,
      phone: null,
    },
    currentPassword: null,
    password: null,
    passwordConfirmation: null,
    showPassword1: false,
    showPassword2: false,
    showPassword3: false,
    nameRules: [(v) => !!v || "Jméno je povinné."],
    phoneRules: [
      (v) => !!v || "Telefon je povinný.",
      (v) => /\b\d{9}\b/.test(v) || "Telefon není validní",
    ],
    emailRules: [
      (v) => !!v || "E-mail je povinný",
      (v) => /.+@.+/.test(v) || "E-mail není validní",
    ],
    passwordRules: [(v) => !!v || "Heslo je povinné"],
  }),
  created() {
    // fetch the data when the view is created and the data is
    // already being observed
    this.fetchData();
  },
};
</script>
